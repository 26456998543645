import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';

// Máscara de CPF
const maskCPF = (cpf) => {
    if (!cpf) return '';
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.XXX.XXX-$4');
};

// Máscara de Celular (mostra apenas os últimos 4 dígitos)
const maskCelular = (celular) => {
    if (!celular) return '';
    // Exibe apenas os últimos 4 dígitos e mascara o restante
    const masked = celular.replace(/\d(?=\d{4})/g, 'X');  // Mascara todos os números, exceto os últimos 4
    return masked;
};

export const DialogCsv = ({ visible, setVisible, inscricao }) => {
    const [selectedInscricoes, setSelectedInscricoes] = useState([]);

    const cols = [
        { field: 'id', header: 'Código' },
        { field: 'perfilNome', header: 'Nome' },
        { field: 'dataNascimento', header: 'Nascimento' },
        { field: 'perfilCpf', header: 'Cpf' },
        { field: 'perfilEmail', header: 'Email' },
        { field: 'perfilCelular', header: 'Celular' },
        { field: 'campanhaNome', header: 'Campanha' },
        { field: 'dataInscricao', header: 'Data' },
        { field: 'sorteado', header: 'Contemplado' }
    ];

    const exportCSV = (selectionOnly) => {
        // Modificando os dados antes de exportar para garantir que 'sorteado' seja 'Sim' ou 'Não'
        const dataToExport = (selectionOnly ? selectedInscricoes : inscricao).map(item => {
            const formattedData = {
                ...item,
                sorteado: item.sorteado ? 'Sim' : 'Não',  // Converte 'true' para 'Sim' e 'false' para 'Não'
                perfilCelular: item.perfilCelular ? maskCelular(item.perfilCelular) : '', // Aplica a máscara de celular
                perfilCpf: item.perfilCpf ? maskCPF(item.perfilCpf) : '', // Aplica a máscara de CPF
            };
            return formattedData;
        });

        // Convertendo os dados para o formato CSV
        const csvContent = generateCSV(dataToExport);

        // Criando um link para download do arquivo CSV
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'inscricoes_export.csv';
        hiddenElement.click();
    };

    // Função para gerar o CSV a partir dos dados
    const generateCSV = (data) => {
        const header = cols.map(col => col.header).join(',') + '\n'; // Cabeçalho do CSV
        const rows = data.map(item => {
            return cols.map(col => {
                const field = col.field;
                // Exibe o valor correspondente ou vazio se não houver dado
                return item[field] ? `"${item[field]}"` : '""';
            }).join(',');
        }).join('\n'); // Linhas de dados

        return header + rows; // Retorna o CSV completo
    };

    const onSelectionChange = (e) => {
        setSelectedInscricoes(e.value);
    };

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '75vw', height: '50vw' }}
        >
            <div className="card">
                <h5>Export</h5>

                <Tooltip target=".export-buttons>button" position="bottom" />

                <DataTable
                    style={{ width: '100%' }}
                    className="p-datatable-responsive-demo"
                    value={inscricao}
                    header={header}
                    dataKey="id"
                    responsiveLayout="scroll"
                    selectionMode="multiple"
                    selection={selectedInscricoes}
                    onSelectionChange={onSelectionChange}
                >
                    {cols.map((col, index) => {
                        // Definindo as larguras das colunas
                        const columnWidths = {
                            'id': '8%',
                            'perfilNome': '8%',
                            'dataNascimento': '15%',
                            'perfilCpf': '10%',
                            'perfilEmail': '35%',
                            'perfilCelular': '12%',
                            'campanhaNome': '10%',
                            'dataInscricao': '10%',
                            'sorteado': '10%',
                        };

                        // Verifica se a largura da coluna está mapeada
                        const columnStyle = columnWidths[col.field] ? { width: columnWidths[col.field] } : {};

                        // Para a coluna 'perfilCelular' e 'perfilCpf', vamos aplicar a máscara para ocultar parte dos valores
                        const columnBody = (rowData) => {
                            if (col.field === 'perfilCelular') {
                                return maskCelular(rowData.perfilCelular);
                            }
                            if (col.field === 'perfilCpf') {
                                return maskCPF(rowData.perfilCpf);
                            }
                            if (col.field === 'sorteado') {
                                return rowData.sorteado ? "Sim" : "Não";
                            }
                            return rowData[col.field]; // Retorna o valor padrão para as outras colunas
                        };

                        return (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                                style={columnStyle}
                                body={columnBody}  // Aplicando a formatação personalizada
                            />
                        );
                    })}
                </DataTable>
            </div>
        </Dialog>
    );
};
