import React, { useRef, useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUserMenu } from '../hooks/useUserMenu';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { Checkbox } from 'primereact/checkbox';
import api from '../api/api';

import '../layout/sass/_form.scss';
import { Printd } from 'printd';
import { confirmDialog } from 'primereact/confirmdialog';
import logo2 from '../assets/logo_conservatorio.jpg';

export const FormMatricula = () => {

    const { isLoading } = useUserMenu();
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [showRematricula, setShowRematricula] = useState(false);
    const [showMatricula, setShowMatricula] = useState(false);
    const [showTurmaCheia, setShowTurmaCheia] = useState(false);
    const [showMatriculaDesistente, setShowMatriculaDesistente] = useState(false);
    const [showSemPerfil, setShowSemPerfil] = useState(false);
    const [showSemInscricao, setShowSemInscricao] = useState(false);
    const [showPerfil, setShowPerfil] = useState(false);
    const [showMatricula2, setShowMatricula2] = useState(false);
    const [showRematricula2, setShowRematricula2] = useState(false);
    const [showPerfilSorteado, setShowPerfilSorteado] = useState(false);
    const [perfil, setPerfil] = useState("");
    const [curso, setCurso] = useState("");
    const [dataHoje, setDataHoje] = useState("");
    const [anoTurma, setAnoTurma] = useState("");
    const [inicioMatricula, setInicioMatricula] = useState("");
    const [inicioMatriculaBr, setInicioMatriculaBr] = useState("");
    const [finalMatricula, setFinalMatricula] = useState("");
    const [finalMatriculaBr, setFinalMatriculaBr] = useState("");
    const [inicioRematricula, setInicioRematricula] = useState("");
    const [inicioRematriculaBr, setInicioRematriculaBr] = useState("");
    const [finalRematricula, setFinalRematricula] = useState("");
    const [finalRematriculaBr, setFinalRematriculaBr] = useState("");
    const [turma, setTurma] = useState([]);
    const [showComprovante, setShowComprovante] = useState(false);
    const [showComprovante2, setShowComprovante2] = useState(false);
    const [showComprovante3, setShowComprovante3] = useState(false);
    const [matricula, setMatricula] = useState([]);
    const selecionar = { label: 'Selecionar', icon: 'pi pi-fw pi-plus' };
    const enviar = { label: 'Enviar', icon: 'pi pi-upload' };
    const cancelar = { label: 'Cancelar', icon: 'pi pi-times' };
    const [disableUploadDoc, setDisableUploadDoc] = useState(true);
    const [disableUploadEnde, setDisableUploadEnde] = useState(true);
    const fileUploadRefDoc = useRef(null);
    const fileUploadRefEnde = useRef(null);
    const [instrumento, setInstrumento] = useState(false);
    const [instrumentoNome, setInstrumentoNome] = useState("");
    const [apenasInstrumento, setApenasInstrumento] = useState(false);
    const [dataConclusao, setDataConclusao] = useState("");
    const [notaCorte] = useState(60);
    const [showCpfRepetido, setShowCpfRepetido] = useState(false);
    const [dadosInscricao, setDadosInscricao] = useState([]);

    const handlerUpload = (event) => {

        let tipo = event.options.props.name;

        let extensao = event.files[0].name.split(".");

        let posicaoExtensao = extensao.length;

        extensao = tipo + "." + extensao[posicaoExtensao - 1];

        const file = new File([event.files[0]], formik.values.cpf + extensao);


        const data = new FormData();

        data.append('doc', file);
        data.append('Content-Type', 'multipart/form-data');

        api.post('/matricula/uploadDoc', data, { "Content-Type": "multipart/form-data" })
        // .then(response => console.log(response.data));
        //event.files == files to upload
    }

    const handleSelectDoc = () => {
        formik.setFieldValue("doc", "documento");
        setDisableUploadDoc(true);
    }

    const handleClearDoc = () => {
        formik.setFieldValue("doc", "");
        setDisableUploadDoc(false);
    }

    const handleSelectEnde = () => {
        formik.setFieldValue("endereco", "endereço");
        setDisableUploadEnde(true);
    }

    const handleClearEnde = () => {
        formik.setFieldValue("endereco", "endereço");
        setDisableUploadEnde(false);
    }

    const defaultValues = {
        'dataMatricula': '',
        'conclusao': '',
        'notaFinal': '',
        'status': '',
        'alunoId': '',
        'cpf': '',
        'instrumentoId': '',
        'turmaId': '',
        'periodoId': '',
        'perfil': '',
        'matriculaTeoria': '',
        'matriculaInstrumento': '',
        'documentos': '',
        'turma': '',
        'doc': '',
        'endereco': '',
        'renovar': ''

    }

    const d = new Printd();
    const cssText = `
      #print-content {
          font-family: 'Open Sans', sans-serif;
          width: 100%;
          text-align: left;
      }
      
      img {
              margin-top: 25px;
              width: 100%;
              max-width: 250px;
              height: auto;
              display: block;
          }
      
          label {
              font-weight: 300;
          }
      
          span {
              font-weight: 500;
          }
      
          .p-dialog-content {
              padding: 0.5em 2em 2em 2em;
          }
      
          .comprovante-header {
              display: flex;
              justify-content: center;
          }
  
          .comprovante-header p {
              margin-top: 35px;
              font-size: 1.3em;
              font-weight: bold;
              text-align: center;
          }
  
          .comprovante-field {
              margin-top: 20px;
              margin bottom: 20px;
          }
      `


    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    const schema = Yup.object().shape({
        alunoId: Yup
            .number()
            .required("O aluno é obrigatório")
            .positive("O aluno deve ser selecionado")
            .integer("O aluno deve estar na lista"),
        instrumentoId: Yup
            .number()
            .required("O instrumento é obrigatório")
            .positive("O instrumento deve ser selecionado")
            .integer("O instrumento deve estar na lista"),
        turmaId: Yup
            .number()
            .test("required", "A turma é obrigatório", () => {
                if (apenasInstrumento) {
                    return true;
                }
                else {
                    return false;
                }
            })
            .required("A turma é obrigatorio")
            .positive("A turma deve ser selecionado")
            .integer("A turma deve estar na lista"),
        cpf: Yup
            .string()
            .required("O CPF é obrigatório"),
        doc: Yup
            .mixed()
            .test("required", "É obrigatório anexar o documento", (doc) => {
                if (doc) return true;
                return false;
            }),
        endereco: Yup
            .mixed()
            .test("required", "É obrigatório anexar o documento", (endereco) => {
                if (endereco) return true;
                return false;
            }),
    })

    const submitPossuiMatriculasTeoriaEInstrumento = async (matriculaTeoria, matriculaInstrumento, data, periodoRematricula) => {
        let turmaTeoria = await api.get(`turma/${data.turmaId}`)
        let qtdeTeoria = await api.get(`matricula/inscritos/${data.turmaId}`)

        let matriculaDataTeoria = matriculaTeoria.dataMatricula;
        // Se a data da matricula anterior está no período de rematriculas
        if (matriculaDataTeoria >= inicioRematricula && matriculaDataTeoria <= finalRematricula) {
            setShowMatricula2(true);
            console.log("Matricula já realizada")
            toast.current.show({ life: 5000, severity: 'error', summary: 'Matricula já realizada!', detail: `Não foi possível matricular o perfil pois uma matricula já foi realizada com esse CPF!` });
        }
        // Se a data da matricula anterior não está no período de rematriculas
        else {
            // Se matricula teoria consta como trancado
            if (matriculaTeoria.status === "TRANCADO") {
                // Se renovou matricula instrumento
                if (instrumento) {
                    const tempMatricula = {
                        'id': matriculaInstrumento.id,
                        'alunoId': matriculaInstrumento.aluno.id,
                        'conclusao': new Date(dataConclusao).toISOString(),
                        'cpf': matriculaInstrumento.aluno.cpf,
                        'dataMatricula': matriculaInstrumento.dataMatricula,
                        'documentos': "",
                        'turmaId': matriculaInstrumento.turma.id,
                        'instrumentoId': matriculaInstrumento.instrumento.id,
                        'notaFinal': matriculaInstrumento.notaFinal,
                        'status': matriculaInstrumento.status,
                        'periodoId': matriculaInstrumento.periodoId
                    }
                    data.matriculaInstrumento = tempMatricula;
                    await api.put("matricula", data.matriculaInstrumento)
                    console.log("Renovou matricula instrumento")
                }
                // Se a quantidade de inscritos é menor que a quantidade de vagas da turma instrumento e teoria
                if (qtdeTeoria.data < turmaTeoria.data.qtdeVagas) {
                    let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                    const tempPerfil = {
                        'id': perfil.data.id,
                        'nome': perfil.data.nome,
                        'cpf': perfil.data.cpf,
                        'email': perfil.data.email,
                        'dataNascimento': perfil.data.dataNascimento,
                        'responsavel': perfil.data.responsavel,
                        'celular': perfil.data.celular,
                        'telefone': perfil.data.telefone,
                        'lgpdAceite': perfil.data.lgpdAceite,
                        'tipo': "ALUNO",
                    }
                    data.perfil = tempPerfil;
                    const tempMatricula = {
                        'alunoId': data.alunoId,
                        'conclusao': "",
                        'cpf': data.cpf,
                        'dataMatricula': new Date(),
                        'documentos': "",
                        'turmaId': data.turmaId,
                        'instrumentoId': data.instrumentoId,
                        'notaFinal': 0,
                        'status': 1,
                        'periodoId': periodoRematricula
                    }
                    data.matriculaTeoria = tempMatricula;
                    await api.post("matricula", data.matriculaTeoria)
                    console.log("Rematriculou")
                    let getId = await api.get(`matricula/cpf/teoria/${data.cpf}`)
                    setMatricula({
                        "id": getId.data[0].id,
                        "nome": data.perfil.nome,
                        'email': data.perfil.email,
                        "nascimento": data.perfil.dataNascimento,
                        'responsavel': data.perfil.responsavel,
                        'celular': data.perfil.celular,
                        "cpf": data.perfil.cpf,
                        "data": new Date()
                    })
                    toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                    confirmDialog({
                        message: `Deseja salvar um comprovante de rematricula?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            setShowComprovante2(true);
                        },
                        reject: () => { return }
                    });
                }
                // Se a quantidade de inscritos é maior ou igual a quantidade de vagas da turma
                else {
                    setShowTurmaCheia(true);
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Turma lotada!', detail: `Não foi possível matricular o perfil pois a turma escolhida está lotada!` });
                    console.log("Turma lotada")
                }
            }
            // Se a matricula não consta como trancado
            else {
                // Se renovou matricula instrumento
                if (instrumento) {
                    const tempMatricula = {
                        'id': matriculaInstrumento.id,
                        'alunoId': matriculaInstrumento.aluno.id,
                        'conclusao': new Date(dataConclusao).toISOString(),
                        'cpf': matriculaInstrumento.aluno.cpf,
                        'dataMatricula': matriculaInstrumento.dataMatricula,
                        'documentos': "",
                        'turmaId': matriculaInstrumento.turma.id,
                        'instrumentoId': matriculaInstrumento.instrumento.id,
                        'notaFinal': matriculaInstrumento.notaFinal,
                        'status': matriculaInstrumento.status,
                        'periodoId': matriculaInstrumento.periodoId
                    }
                    data.matriculaInstrumento = tempMatricula;
                    await api.put("matricula", data.matriculaInstrumento)
                    console.log("Renovou matricula instrumento")
                }
                // Se a quantidade de inscritos é menor que a quantidade de vagas da turma instrumento e teoria
                if (qtdeTeoria.data < turmaTeoria.data.qtdeVagas) {
                    let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                    const tempPerfil = {
                        'id': perfil.data.id,
                        'nome': perfil.data.nome,
                        'cpf': perfil.data.cpf,
                        'email': perfil.data.email,
                        'dataNascimento': perfil.data.dataNascimento,
                        'responsavel': perfil.data.responsavel,
                        'celular': perfil.data.celular,
                        'telefone': perfil.data.telefone,
                        'lgpdAceite': perfil.data.lgpdAceite,
                        'tipo': "ALUNO",
                    }
                    data.perfil = tempPerfil;
                    const tempMatricula = {
                        'alunoId': data.alunoId,
                        'conclusao': "",
                        'cpf': data.cpf,
                        'dataMatricula': new Date(),
                        'documentos': "",
                        'turmaId': data.turmaId,
                        'instrumentoId': data.instrumentoId,
                        'notaFinal': 0,
                        'status': 1,
                        'periodoId': periodoRematricula,
                    }
                    data.matriculaTeoria = tempMatricula;
                    await api.post("matricula", data.matriculaTeoria)
                    console.log("Rematriculou")
                    let getId = await api.get(`matricula/cpf/teoria/${data.cpf}`)
                    setMatricula({
                        "id": getId.data[0].id,
                        "nome": data.perfil.nome,
                        'email': data.perfil.email,
                        "nascimento": data.perfil.dataNascimento,
                        'responsavel': data.perfil.responsavel,
                        'celular': data.perfil.celular,
                        "cpf": data.perfil.cpf,
                        "data": new Date()
                    })
                    toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                    confirmDialog({
                        message: `Deseja salvar um comprovante de rematricula?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            setShowComprovante2(true);
                        },
                        reject: () => { return }
                    });
                }
                // Se a quantidade de inscritos é maior ou igual a quantidade de vagas da turma
                else {
                    setShowTurmaCheia(true);
                    console.log("Turma lotada")
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Turma lotada!', detail: `Não foi possível matricular o perfil pois a turma escolhida está lotada!` });
                }
            }
        }
    }

    const submitPossuiMatriculasTeoriaOuInstrumento = async (matriculaInstrumento, matricula, data, periodoRematricula) => {
        let matriculaData = matricula.dataMatricula;
        // Se a data da matricula anterior está no período de rematriculas
        if (matriculaData >= inicioRematricula && matriculaData <= finalRematricula) {
            setShowMatricula2(true);
            console.log("Matricula já realizada")
            toast.current.show({ life: 5000, severity: 'error', summary: 'Matricula já realizada!', detail: `Não foi possível matricular o perfil pois uma matricula já foi realizada com esse CPF!` });
        }
        // Se a data da matricula anterior não está no período de rematriculas
        else {
            // Se a matricula anterior consta como trancado
            if (matricula.status === "TRANCADO") {
                // Se matricula é do tipo instrumento
                if (instrumento) {
                    const tempMatricula = {
                        'id': matriculaInstrumento.id,
                        'alunoId': matriculaInstrumento.aluno.id,
                        'conclusao': new Date(dataConclusao).toISOString(),
                        'cpf': matriculaInstrumento.aluno.cpf,
                        'dataMatricula': matriculaInstrumento.dataMatricula,
                        'documentos': "",
                        'turmaId': matriculaInstrumento.turma.id,
                        'instrumentoId': matriculaInstrumento.instrumento.id,
                        'notaFinal': matriculaInstrumento.notaFinal,
                        'status': matriculaInstrumento.status,
                        'periodoId': matriculaInstrumento.periodoId
                    }
                    data.matriculaInstrumento = tempMatricula;
                    await api.put("matricula", data.matriculaInstrumento)
                    console.log("Renovou matricula instrumento")
                    let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                    const tempPerfil = {
                        'id': perfil.data.id,
                        'nome': perfil.data.nome,
                        'cpf': perfil.data.cpf,
                        'email': perfil.data.email,
                        'dataNascimento': perfil.data.dataNascimento,
                        'responsavel': perfil.data.responsavel,
                        'celular': perfil.data.celular,
                        'telefone': perfil.data.telefone,
                        'lgpdAceite': perfil.data.lgpdAceite,
                        'tipo': "ALUNO",
                    }
                    data.perfil = tempPerfil;
                    let getId = await api.get(`matricula/cpf/${data.cpf}`)
                    setMatricula({
                        "id": getId.data[0].id,
                        "nome": data.perfil.nome,
                        'email': data.perfil.email,
                        "nascimento": data.perfil.dataNascimento,
                        'responsavel': data.perfil.responsavel,
                        'celular': data.perfil.celular,
                        "cpf": data.perfil.cpf,
                        "data": new Date()
                    })
                    toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                    confirmDialog({
                        message: `Deseja salvar um comprovante de rematricula?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            setShowComprovante2(true);
                        },
                        reject: () => { return }
                    });
                }
                // Se matricula é do tipo teoria
                else {
                    let turma = await api.get(`turma/${data.turmaId}`)
                    let qtde = await api.get(`matricula/inscritos/${data.turmaId}`)
                    // Se a quantidade de inscritos é menor que a quantidade de vagas da turma
                    if (qtde.data < turma.data.qtdeVagas) {
                        let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                        const tempPerfil = {
                            'id': perfil.data.id,
                            'nome': perfil.data.nome,
                            'cpf': perfil.data.cpf,
                            'email': perfil.data.email,
                            'dataNascimento': perfil.data.dataNascimento,
                            'responsavel': perfil.data.responsavel,
                            'celular': perfil.data.celular,
                            'telefone': perfil.data.telefone,
                            'lgpdAceite': perfil.data.lgpdAceite,
                            'tipo': "ALUNO",
                        }
                        data.perfil = tempPerfil;
                        data.notaFinal = 0;
                        data.status = "REMATRICULADO";
                        data.dataMatricula = new Date();
                        data.periodoId = periodoRematricula;
                        await api.post("matricula", data)
                        console.log("Rematriculou")
                        let getId = await api.get(`matricula/cpf/${data.cpf}`)
                        setMatricula({
                            "id": getId.data[0].id,
                            "nome": data.perfil.nome,
                            'email': data.perfil.email,
                            "nascimento": data.perfil.dataNascimento,
                            'responsavel': data.perfil.responsavel,
                            'celular': data.perfil.celular,
                            "cpf": data.perfil.cpf,
                            "data": new Date()
                        })
                        toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                        confirmDialog({
                            message: `Deseja salvar um comprovante de rematricula?`,
                            header: 'Confirmação',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Sim',
                            rejectLabel: 'Não',
                            accept: async () => {
                                setShowComprovante2(true);
                            },
                            reject: () => { return }
                        });

                    }
                    // Se a quantidade de inscritos é maior ou igual a quantidade de vagas da turma
                    else {
                        setShowTurmaCheia(true);
                        console.log("Turma lotada")
                        toast.current.show({ life: 5000, severity: 'error', summary: 'Turma lotada!', detail: `Não foi possível matricular o perfil pois a turma escolhida está lotada!` });
                    }
                }
            }
            // Se a matricula anterior não consta como trancado
            else {
                // Se matricula é do tipo instrumento
                if (instrumento) {
                    const tempMatricula = {
                        'id': matriculaInstrumento.id,
                        'alunoId': matriculaInstrumento.aluno.id,
                        'conclusao': new Date(dataConclusao).toISOString(),
                        'cpf': matriculaInstrumento.aluno.cpf,
                        'dataMatricula': matriculaInstrumento.dataMatricula,
                        'documentos': "",
                        'turmaId': matriculaInstrumento.turma.id,
                        'instrumentoId': matriculaInstrumento.instrumento.id,
                        'notaFinal': matriculaInstrumento.notaFinal,
                        'status': matriculaInstrumento.status,
                        'periodoId': matriculaInstrumento.periodoId
                    }
                    data.matriculaInstrumento = tempMatricula;
                    await api.put("matricula", data.matriculaInstrumento)
                    console.log("Renovou matricula instrumento")
                    let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                    const tempPerfil = {
                        'id': perfil.data.id,
                        'nome': perfil.data.nome,
                        'cpf': perfil.data.cpf,
                        'email': perfil.data.email,
                        'dataNascimento': perfil.data.dataNascimento,
                        'responsavel': perfil.data.responsavel,
                        'celular': perfil.data.celular,
                        'telefone': perfil.data.telefone,
                        'lgpdAceite': perfil.data.lgpdAceite,
                        'tipo': "ALUNO",
                    }
                    data.perfil = tempPerfil;
                    let getId = await api.get(`matricula/cpf/${data.cpf}`)
                    setMatricula({
                        "id": getId.data[0].id,
                        "nome": data.perfil.nome,
                        'email': data.perfil.email,
                        "nascimento": data.perfil.dataNascimento,
                        'responsavel': data.perfil.responsavel,
                        'celular': data.perfil.celular,
                        "cpf": data.perfil.cpf,
                        "data": new Date()
                    })
                    toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                    confirmDialog({
                        message: `Deseja salvar um comprovante de rematricula?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            setShowComprovante2(true);
                        },
                        reject: () => { return }
                    });
                }
                // Se matricula é do tipo teoria
                else {
                    let turma = await api.get(`turma/${data.turmaId}`)
                    let qtde = await api.get(`matricula/inscritos/${data.turmaId}`)
                    // Se a quantidade de inscritos é menor que a quantidade de vagas da turma
                    if (qtde.data < turma.data.qtdeVagas) {
                        let perfil = await api.get(`perfil/cpf/${data.cpf}`)
                        const tempPerfil = {
                            'id': perfil.data.id,
                            'nome': perfil.data.nome,
                            'cpf': perfil.data.cpf,
                            'email': perfil.data.email,
                            'dataNascimento': perfil.data.dataNascimento,
                            'responsavel': perfil.data.responsavel,
                            'celular': perfil.data.celular,
                            'telefone': perfil.data.telefone,
                            'lgpdAceite': perfil.data.lgpdAceite,
                            'tipo': "ALUNO",
                        }
                        data.perfil = tempPerfil;
                        data.notaFinal = 0;
                        data.status = "REMATRICULADO";
                        data.dataMatricula = new Date();
                        data.periodoId = periodoRematricula;
                        await api.post("matricula", data)
                        console.log("Rematriculou")
                        let getId = await api.get(`matricula/cpf/${data.cpf}`)
                        setMatricula({
                            "id": getId.data[0].id,
                            "nome": data.perfil.nome,
                            'email': data.perfil.email,
                            "nascimento": data.perfil.dataNascimento,
                            'responsavel': data.perfil.responsavel,
                            'celular': data.perfil.celular,
                            "cpf": data.perfil.cpf,
                            "data": new Date()
                        })
                        toast.current.show({ life: 4000, severity: 'success', summary: 'Cadastro concluído!', detail: `Rematricula realizada com sucesso` });
                        confirmDialog({
                            message: `Deseja salvar um comprovante de rematricula?`,
                            header: 'Confirmação',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Sim',
                            rejectLabel: 'Não',
                            accept: async () => {
                                setShowComprovante2(true);
                            },
                            reject: () => { return }
                        });

                    }
                    // Se a quantidade de inscritos é maior ou igual a quantidade de vagas da turma
                    else {
                        setShowTurmaCheia(true);
                        console.log("Turma lotada")
                        toast.current.show({ life: 5000, severity: 'error', summary: 'Turma lotada!', detail: `Não foi possível matricular o perfil pois a turma escolhida está lotada!` });
                    }
                }
            }
        }
    }

    const submitMatricula = async (matricula, matriculaExiste, data, periodoMatricula, matriculaData) => {
        // Verifica o status da matrícula
        if (matricula.status === "DESISTENTE" || matriculaExiste.data.length === 0) {
            let turma = await api.get(`turma/${data.turmaId}`);
            let qtde = await api.get(`matricula/inscritos/${data.turmaId}`);

            // Verifica se há vagas na turma
            if (qtde.data < turma.data.qtdeVagas) {
                let perfil = await api.get(`perfil/cpf/${data.cpf}`);

                const tempPerfil = {
                    'id': perfil.data.id,
                    'nome': perfil.data.nome,
                    'cpf': perfil.data.cpf,
                    'email': perfil.data.email,
                    'dataNascimento': perfil.data.dataNascimento,
                    'responsavel': perfil.data.responsavel,
                    'celular': perfil.data.celular,
                    'telefone': perfil.data.telefone,
                    'lgpdAceite': perfil.data.lgpdAceite,
                    'tipo': "ALUNO",
                };

                let inscricao = await api.get(`inscricoes/perfil/${perfil.data.id}`);
                setDadosInscricao(inscricao.data);
                data.perfil = tempPerfil;
                await api.put("perfil", data.perfil)
                console.log("Atualizou Perfil")
                data.notaFinal = 0;
                data.status = "MATRICULADO";
                data.dataMatricula = new Date().toISOString();
                data.conclusao = "";
                data.periodoId = periodoMatricula;
                await api.post("matricula", data)
                console.log("Matriculou")
                let getId = await api.get(`matricula/cpf/${data.cpf}`)
                setMatricula({
                    "id": getId.data[0].id,
                    "nome": data.perfil.nome,
                    'email': data.perfil.email,
                    "nascimento": data.perfil.dataNascimento,
                    'responsavel': data.perfil.responsavel,
                    'celular': data.perfil.celular,
                    "cpf": data.perfil.cpf,
                    "data": new Date()
                })
                toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Matricula realizada com sucesso` });
                confirmDialog({
                    message: `Deseja salvar um comprovante de matricula?`,
                    header: 'Confirmação',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: async () => {
                        setShowComprovante(true);
                    },
                    reject: () => { return }
                });
            }
            // Se a quantidade de inscritos é maior ou igual a quantidade de vagas da turma
            else {
                console.log("Turma lotada")
                setShowTurmaCheia(true);
                toast.current.show({ life: 5000, severity: 'error', summary: 'Turma lotada!', detail: `Não foi possível matricular o perfil pois a turma escolhida está lotada!` });
            }

        }
        // Se possui matricula e a matricula anterior não consta como desistente
        else {
            // Se a data da matricula anterior está no período de matriculas
            if (matriculaData >= inicioMatricula && matriculaData <= finalMatricula) {
                setShowMatricula2(true);
                console.log("Matricula já realizada")
                toast.current.show({ life: 5000, severity: 'error', summary: 'Matricula já realizada!', detail: `Não foi possível matricular o perfil pois uma matricula já foi realizada com esse CPF!` });
            }
            // Se a data da matricula anterior não está no período de matriculas
            else {
                setShowRematricula(true);
                console.log("Matricula fora do período")
                toast.current.show({ life: 5000, severity: 'error', summary: 'Matricula já realizada!', detail: `Não foi possível matricular o perfil pois uma matricula já foi realizada com esse CPF!` });
            }
        }
    }

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)
            setDisableUploadDoc(true);
            setDisableUploadEnde(true);
            setTurma([]);
            fileUploadRefDoc.current.clear()
            fileUploadRefEnde.current.clear()
            formik.resetForm()
            setTimeout(() => {
                history.push('/')

            }, 2000)
            let matriculaExiste = await api.get(`matricula/cpf/${data.cpf}`)
            let matricula = matriculaExiste.data[0] !== undefined ? matriculaExiste.data[0] : "";
            let matriculaData = matricula.dataMatricula !== undefined ? matricula.dataMatricula : "";
            let periodo = await api.get(`periodoMatricula?sort=dataInicial,DESC`);
            let periodoMatricula = periodo.data.content[1].id;
            let periodoRematricula = periodo.data.content[0].id;
            try {
                // Se a data de hoje está no período de rematriculas
                if (dataHoje >= inicioRematricula && dataHoje <= finalRematricula) {
                    let matriculaTeoriaExiste = await api.get(`matricula/cpf/teoria/${data.cpf}`)
                    let matriculaInstrumentoExiste = await api.get(`matricula/cpf/instrumento/${data.cpf}`)
                    // Se possui matricula em turma teoria e possui matricula em turma instrumento
                    if (matriculaTeoriaExiste.data.length !== 0 && matriculaInstrumentoExiste.data.length !== 0) {
                        submitPossuiMatriculasTeoriaEInstrumento(matriculaTeoriaExiste.data[0], matriculaInstrumentoExiste.data[0], data, periodoRematricula);
                    }
                    // Se não possui matricula em turma teoria ou não possui matricula em turma instrumento
                    else {
                        submitPossuiMatriculasTeoriaOuInstrumento(matriculaInstrumentoExiste.data[0], matricula, data, periodoRematricula)
                    }
                }
                // Se a data de hoje está no período de matriculas
                if (dataHoje >= inicioMatricula && dataHoje <= finalMatricula) {
                    submitMatricula(matricula, matriculaExiste, data, periodoMatricula, matriculaData)
                }
            }
            catch (error) {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao realizar matrícula!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getPerfil = useCallback(async () => {
        try {
            const response = await api.get(`perfil/perfilAll`);
            setPerfil(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter perfil', detail: error.toString() });
        }
    }, []);

    const getCursos = useCallback(async () => {
        try {
            const response = await api.get(`curso/instrumentoEspera`);
            setCurso(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    const getPeriodoMatricula = useCallback(async () => {
        try {
            const response = await api.get(`periodoMatricula?sort=dataInicial,DESC`);
            setInicioRematricula(response.data.content[1].dataInicial)
            setFinalRematricula(response.data.content[1].dataFinal)
            setInicioMatricula(response.data.content[0].dataInicial)
            setFinalMatricula(response.data.content[0].dataFinal)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    const getDataHoje = useCallback(async () => {
        try {
            setDataHoje(new Date().toISOString())
            setAnoTurma(new Date().getFullYear())
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    const getDataFormatada = useCallback(async () => {
        try {
            const response = await api.get(`periodoMatricula?sort=dataInicial,DESC`);
            setInicioMatriculaBr(new Date(response.data.content[0].dataInicial).toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
            setFinalMatriculaBr(new Date(response.data.content[0].dataFinal).toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
            setInicioRematriculaBr(new Date(response.data.content[1].dataInicial).toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
            setFinalRematriculaBr(new Date(response.data.content[1].dataFinal).toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        async function matricula() {
            await getDataHoje();
            await getPerfil();
            await getCursos();
            await getPeriodoMatricula();
            await getDataFormatada();
        };
        matricula();
    }, [getDataHoje, getPerfil, getCursos,
        getPeriodoMatricula, getDataFormatada])

    const mapearTurma = (data) => {



        return data.map(turma => {
            var turm = { label: '', value: '' };
            turm.label = turma.cursoNome + " - " + turma.diaSemana + "  " + turma.hora
            turm.value = turma.id

            return turm
        })
    }

    const mapearTurmaParaInstrumento = () => {

        let tempTurmas = [];

        let turm = { label: 'Turma Concluída', value: 5500 };

        tempTurmas.push(turm);
        return tempTurmas

    }

    const semPerfil = () => {
        setShowSemPerfil(true);
        formik.resetForm();
        setTurma([]);
        console.log("Não possui perfil!")
    }

    const semMatriculaOuDesistente = async (perfil, inscricao) => {
        let sorteado = "";
        if (inscricao.length !== 0) {
            sorteado = await api.get(`inscricoes/perfil/${perfil.id}`);
        }
        let ano = "";
        let anoNascimento = "";
        let nascimento = "";
        let faixaEtaria = "";
        let anoSorteado = "";
        let anoMatricula = "";
        if (sorteado !== "" && sorteado.data[0].sorteado) {
            // Obtém o ano atual
            const anoAtual = new Date().getFullYear();

            // Obtém a data de nascimento
            const dataNascimento = new Date(sorteado.data[0].perfil.dataNascimento);
            const anoNascimento = dataNascimento.getFullYear();
            const mesNascimento = dataNascimento.getMonth(); // Meses começam do 0 (janeiro é 0)
            const diaNascimento = dataNascimento.getDate();

            // Calcula a idade considerando ano, mês e dia
            let idade = anoAtual - anoNascimento;

            // Verifica se já fez aniversário esse ano ou não
            const dataHoje = new Date();
            if (dataHoje.getMonth() < mesNascimento || (dataHoje.getMonth() === mesNascimento && dataHoje.getDate() < diaNascimento)) {
                idade--; // Se ainda não fez aniversário no ano atual, subtrai um ano
            }            

            faixaEtaria = await api.get(`turma/idade/${idade}/${anoTurma}`)

            anoSorteado = new Date(sorteado.data[0].dataInscricao).getFullYear();
            anoMatricula = new Date(inicioMatricula).getFullYear();
        }

        // Se possui perfil e possui inscrição
        if (perfil !== "" && inscricao.length !== 0) {
            setDisableUploadDoc(false);
            setDisableUploadEnde(false);
            // Se a inscrição anterior consta como sorteada
            if (sorteado.data[0].sorteado) {
                // Se estamos no período de matriculas
                if (dataHoje >= inicioMatricula && dataHoje <= finalMatricula) {
                    // Se o ano da inscrição anterior é o mesmo ano do período de matriculas atual
                    if (anoSorteado === anoMatricula) {
                        setShowPerfilSorteado(true);
                        formik.setFieldValue("alunoId", perfil.id);
                        try {
                            setTurma(mapearTurma(faixaEtaria.data));
                        }
                        catch (error) {
                            toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
                        }
                        console.log("Inscrição sorteada e no ano atual!")
                    }
                    // Se o ano da inscrição anterior não é o mesmo ano do período de matriculas atual
                    else {
                        setShowPerfil(true);
                        formik.resetForm();
                        setTurma([]);
                        console.log("Inscrição sorteada mas não no ano atual!")
                    }
                }
                // Se não estamos no período de matriculas
                else {
                    setShowRematricula2(true);
                    formik.resetForm();
                    setTurma([]);
                    console.log("Inscrição sorteada mas fora do período!")
                }
            }
            // Se a inscrição anterior não consta como sorteada
            else {
                // Se estamos no período de matriculas
                if (dataHoje >= inicioMatricula && dataHoje <= finalMatricula) {
                    setShowPerfil(true);
                    formik.resetForm();
                    setTurma([]);
                    console.log("Inscrição não sorteada!")
                }
                // Se não estamos no período de matriculas
                else {
                    setShowRematricula2(true);
                    formik.resetForm();
                    setTurma([]);
                    console.log("Inscrição não sorteada e fora do período!")
                }
            }
        }
        // Se não possui inscrição
        else {
            setShowSemInscricao(true);
            formik.resetForm();
            setTurma([]);
            console.log("Não possui inscrição")
        }
    }

    const possuiMatriculasTeoriaEInstrumento = async (matricula, matriculaTeoria) => {

        matricula.instrumento ? setInstrumentoNome(matricula.instrumento.nome) : setInstrumentoNome("Instrumento")
        setInstrumento(true);
        formik.setFieldValue("instrumentoId", matricula.instrumento.id);
        setDataConclusao(`${anoTurma}/12/31`)
        setShowMatricula(true);
        setDisableUploadDoc(false);
        setDisableUploadEnde(false);
        formik.setFieldValue("alunoId", matriculaTeoria.aluno.id);

        //let posterior = await api.get(`curso/posterior/${matriculaTeoria.turma.curso.id}`)            
        //Define o curso teórico em razão da nota
        let posterior = [];
        if (matriculaTeoria.notaFinal >= notaCorte) {
            posterior = await api.get(`curso/posterior/${matriculaTeoria.turma.curso.id}`);
        } else {
            posterior = await api.get(`curso/${matriculaTeoria.turma.curso.id}`);
        }

        // Se a turma teoria possui um curso posterior
        if (posterior !== "" && posterior.data !== "") {
            let curso = await api.get(`turma/curso/${posterior.data.id}/${anoTurma}`)
            try {
                // Se o curso anterior consta como musicalização
                if (matricula.turma.curso.id === 1) {
                    let curso = await api.get(`turma/musicalizacao/${matricula.turma.perfil.id}/${anoTurma}`)
                    setTurma(mapearTurma(curso.data));
                    console.log("Curso anterior consta como musicalização!")
                }
                // Se o curso anterior não consta como musicalização
                else {
                    setTurma(mapearTurma(curso.data));
                    console.log("Curso anterior não consta como musicalização!")
                }
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
            }
        }
        // Se a turma teoria não possui um curso posterior
        else {
            try {
                let curso = await api.get(`turma/curso/9999999999999999`)
                setTurma(mapearTurma(curso.data));
                console.log("Turma não possui um curso posterior!")
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
            }

        }
    }

    const possuiMatriculaTeoriaOuInstrumento = async (matricula) => {
        setDisableUploadDoc(false);
        setDisableUploadEnde(false);
        // Se a matricula anterior não consta como desistente e não consta como trancado
        if (matricula.status !== "DESISTENTE" && matricula.status !== "TRANCADO") {
            //Define o curso teórico em razão da nota
            let posterior = "";
            if (matricula.turma.curso.tipo === "TEORIA") {
                if (matricula.notaFinal >= notaCorte) {
                    posterior = await api.get(`curso/posterior/${matricula.turma.curso.id}`);
                } else {
                    posterior = await api.get(`curso/${matricula.turma.curso.id}`);
                }
            }
            // Se o curso da matricula anterior possui um curso posterior
            if (posterior !== "" && posterior.data !== "") {
                let curso = await api.get(`turma/curso/${posterior.data.id}/${anoTurma}`)
                setShowMatricula(true);
                formik.setFieldValue("alunoId", matricula.aluno.id);
                try {
                    // Se o curso anterior consta como musicalização
                    if (matricula.turma.curso.id === 1) {
                        let curso = await api.get(`turma/musicalizacao/${matricula.turma.perfil.id}/${anoTurma}`)
                        setTurma(mapearTurma(curso.data));
                        console.log("Curso anterior consta como musicalização! (Apenas Teoria)")
                    }
                    // Se o curso anterior não consta como musicalização
                    else {
                        setTurma(mapearTurma(curso.data));
                        console.log("Curso anterior não consta como musicalização! (Apenas Teoria)")
                    }
                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
                }
            }
            // Se o curso da matricula anterior não possui um curso posterior
            else {
                let curso = await api.get(`turma/curso/${matricula.turma.curso.id}/${anoTurma}`)
                // Se possui uma turma disponivel com o curso anterior
                if (curso.data !== "") {
                    setShowMatricula(true);
                    formik.setFieldValue("alunoId", matricula.aluno.id);
                    // Se a matricula é do tipo instrumento
                    if (curso.data[0].cursoTipo === "INSTRUMENTO") {
                        matricula.instrumento != null && matricula.instrumento !== "" ? setInstrumentoNome(matricula.instrumento.nome) : setInstrumentoNome("Instrumento")
                        setInstrumento(true);
                        setApenasInstrumento(true);
                        formik.setFieldValue("instrumentoId", matricula.instrumento.id);
                        setDataConclusao(`${anoTurma}/12/31`)
                        setTurma(mapearTurmaParaInstrumento());
                        console.log("Matricula do tipo instrumento!")
                    }
                    else {
                        try {
                            // Se a matricula é do tipo teoria
                            setTurma(mapearTurma(curso.data));
                            console.log("Matricula do tipo teoria!")
                        }
                        catch (error) {
                            toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
                        }
                    }

                }
                // Se não possui uma turma disponivel com o curso anterior
                else {
                    let curso = await api.get(`turma/curso/9999999999999999`)
                    setShowMatricula(true);
                    formik.setFieldValue("alunoId", matricula.aluno.id);
                    setTurma(mapearTurma(curso.data));
                    console.log("Sem turma disponível!")
                }
            }
        }
        // Se a matricula anterior consta como desistente ou trancado
        else {
            // Se a matricula anterior consta como trancado
            if (matricula.status === "TRANCADO") {
                let curso = await api.get(`turma/curso/${matricula.turma.curso.id}/${anoTurma}`)
                setShowMatricula(true);
                setDisableUploadDoc(false);
                setDisableUploadEnde(false);
                formik.setFieldValue("alunoId", matricula.aluno.id);
                try {
                    if (curso.data[0].cursoTipo === "INSTRUMENTO") {
                        matricula.instrumento != null && matricula.instrumento !== "" ? setInstrumentoNome(matricula.instrumento.nome) : setInstrumentoNome("Instrumento")
                        setInstrumento(true);
                        setApenasInstrumento(true);
                        formik.setFieldValue("instrumentoId", matricula.instrumento.id);
                        setDataConclusao(`${anoTurma}/12/31`);
                        console.log("Matricula instrumento consta como trancado!")
                    }
                    else {
                        setTurma(mapearTurma(curso.data));
                        console.log("Matricula teoria consta como trancado!")
                    }

                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
                }
            }
            // Se a matricula anterior consta como desistente
            else {
                setShowMatriculaDesistente(true);
                formik.resetForm();
                setTurma([]);
                console.log("Matricula consta como desistente")
            }
        }
    }

    const handleCpfComplete = async (data) => {


        let matriculaExiste = await api.get(`matricula/cpf/${data.value}`);


        let matriculaTeoriaExiste = await api.get(`matricula/cpf/teoria/${data.value}`);


        let matriculaInstrumentoExiste = await api.get(`matricula/cpf/instrumento/${data.value}`);


        let matricula = matriculaExiste.data[0] !== undefined ? matriculaExiste.data[0] : "";
        let perfilExiste = await api.get(`perfil/cpf/${data.value}`);
        let inscricaoExiste = await api.get(`inscricoes/perfilCpf/${data.value}`);
        // Se não possui perfil
        if (perfilExiste.data === "") {
            semPerfil();
        }
        else {
            // Se não possui matricula ou possui matricula desistente
            if (matriculaExiste.data.length === 0 || matricula.status === "DESISTENTE") {
                semMatriculaOuDesistente(perfilExiste.data, inscricaoExiste.data);
            }
            // se possui matricula e matricula != desistente
            else {
                // Se possui matricula em turma teoria e possui matricula em turma instrumento
                if (matriculaTeoriaExiste.data.length !== 0 && matriculaInstrumentoExiste.data.length !== 0) {
                    possuiMatriculasTeoriaEInstrumento(matricula, matriculaTeoriaExiste.data[0])

                }
                // Se não possui matricula em turma teoria ou não possui matricula em turma instrumento
                else {
                    possuiMatriculaTeoriaOuInstrumento(matricula)
                }
            }
        }

    }

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <Dialog
                visible={showComprovante}
                onHide={() => setShowComprovante(false)}
                header={header}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '20vw', heigth: '5vw' }}
            >
                <div id="print-content">
                    <div className="comprovante-header">
                        <img
                            id="logo"
                            alt="Logo"
                            src={logo2}
                        />
                    </div>
                    <div className="comprovante-header">
                        <p>Comprovante de Matricula</p>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <b><span>{dadosInscricao.length > 0 && dadosInscricao[0].campanha.nome}</span></b>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <label>Código: </label>
                        <span>{matricula.id}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Matriculado: </label>
                        <span>{matricula.nome}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Email: </label>
                        <span>{matricula.email}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Data de Nascimento: </label>
                        <span>{new Date(matricula.nascimento).toLocaleDateString('pt-BR')}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Realizada em: </label>
                        <span>{new Date(matricula.data).toLocaleString('pt-BR')}</span>
                    </div>
                    {matricula.responsavel !== "" ?
                        <div className="p-pt-2 comprovante-field">
                            <label>Responsável: </label>
                            <span>{matricula.responsavel}</span>
                        </div>
                        :
                        ""
                    }
                    <div className="p-py-2 comprovante-field">
                        <label>Celular: </label>
                        <span>{matricula.celular}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>CPF: </label>
                        <span>{matricula.cpf}</span>
                    </div>
                </div>

            </Dialog>
            <Dialog
                visible={showComprovante2}
                onHide={() => setShowComprovante2(false)}
                header={header}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '20vw', heigth: '5vw' }}
            >
                <div id="print-content">
                    <div className="comprovante-header">
                        <img
                            id="logo"
                            alt="Logo"
                            src={logo2}
                        />
                    </div>
                    <div className="comprovante-header">
                        <p>Comprovante de Rematricula</p>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <label>Código: </label>
                        <span>{matricula.id}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Rematriculado: </label>
                        <span>{matricula.nome}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Email: </label>
                        <span>{matricula.email}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Data de Nascimento: </label>
                        <span>{new Date(matricula.nascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Realizada em: </label>
                        <span>{new Date(matricula.data).toLocaleString('pt-BR', { timeZone: 'UTC' })}</span>
                    </div>
                    {matricula.responsavel !== "" ?
                        <div className="p-pt-2 comprovante-field">
                            <label>Responsável: </label>
                            <span>{matricula.responsavel}</span>
                        </div>
                        :
                        ""
                    }
                    <div className="p-py-2 comprovante-field">
                        <label>Celular: </label>
                        <span>{matricula.celular}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>CPF: </label>
                        <span>{matricula.cpf}</span>
                    </div>
                </div>

            </Dialog>
            <Dialog
                visible={showComprovante3}
                onHide={() => setShowComprovante3(false)}
                header={header}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '20vw', heigth: '5vw' }}
            >
                <div id="print-content">
                    <div className="comprovante-header">
                        <img
                            id="logo"
                            alt="Logo"
                            src={logo2}
                        />
                    </div>
                    <div className="comprovante-header">
                        <p>Comprovante de Rematricula</p>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <label>Código Matricula Instrumento: </label>
                        <span>{matricula.id}</span>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <label>Código Matricula Teoria: </label>
                        <span>{matricula.idTeoria}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Rematriculado: </label>
                        <span>{matricula.nome}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Email: </label>
                        <span>{matricula.email}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Data de Nascimento: </label>
                        <span>{new Date(matricula.nascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Realizada em: </label>
                        <span>{new Date(matricula.data).toLocaleString('pt-BR', { timeZone: 'UTC' })}</span>
                    </div>
                    {matricula.responsavel !== "" ?
                        <div className="p-pt-2 comprovante-field">
                            <label>Responsável: </label>
                            <span>{matricula.responsavel}</span>
                        </div>
                        :
                        ""
                    }
                    <div className="p-py-2 comprovante-field">
                        <label>Celular: </label>
                        <span>{matricula.celular}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>CPF: </label>
                        <span>{matricula.cpf}</span>
                    </div>
                </div>

            </Dialog>
            <Dialog
                visible={showMatricula}
                header={"Atenção!"}
                onHide={() => setShowMatricula(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Cadastro encontrado! Por favor, preencha o formulário abaixo para realizar a sua matricula.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowMatricula(false)} />
            </Dialog>
            <Dialog
                visible={showMatricula2}
                header={"Atenção!"}
                onHide={() => setShowMatricula2(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    {dataHoje >= inicioRematricula && dataHoje <= finalRematricula ?
                        <span style={{ textAlign: 'justify' }}>
                            Uma rematricula já foi realizada para esse período!
                        </span>
                        :
                        <span style={{ textAlign: 'justify' }}>
                            Uma matricula já foi realizada para esse período!
                        </span>}

                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowMatricula2(false)} />
            </Dialog>
            <Dialog
                visible={showRematricula}
                header={"Atenção!"}
                onHide={() => setShowRematricula(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Uma rematricula já foi realizada no período anterior!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowRematricula(false)} />
            </Dialog>
            <Dialog
                visible={showRematricula2}
                header={"Atenção!"}
                onHide={() => setShowRematricula2(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        O período de rematriculas é reservado para alunos que já possuem matricula! Fique atento ao período de matriculas na data {inicioMatriculaBr} a {finalMatriculaBr}.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowRematricula2(false)} />
            </Dialog>
            <Dialog
                visible={showMatriculaDesistente}
                header={"Atenção!"}
                onHide={() => setShowMatriculaDesistente(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Matricula com situação desistente! Por favor, caso deseje se matricular novamente realize uma nova inscrição quando a próxima campanha estiver disponível.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowMatriculaDesistente(false)} />
            </Dialog>
            <Dialog
                visible={showSemPerfil}
                header={"Atenção!"}
                onHide={() => setShowSemPerfil(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Cadastro não encontrado! Por gentileza, procurar a administração do conservatório.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowSemPerfil(false)} />
            </Dialog>
            <Dialog
                visible={showSemInscricao}
                header={"Atenção!"}
                onHide={() => setShowSemInscricao(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Inscrição não encontrada! Por gentileza, procurar a administração do conservatório.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowSemInscricao(false)} />
            </Dialog>
            <Dialog
                visible={showCpfRepetido}
                header={"Atenção!"}
                onHide={() => setShowCpfRepetido(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Foram encontrados mais de uma matricula com esse CPF! Por gentileza, procurar a administração do conservatório.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowCpfRepetido(false)} />
            </Dialog>
            <Dialog
                visible={showPerfil}
                header={"Atenção!"}
                onHide={() => setShowPerfil(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Cadastro encontrado. Infelizmente sua inscrição não foi sorteada! Por favor, realize a inscrição novamente quando a próxima campanha estiver disponível.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowPerfil(false)} />
            </Dialog>
            <Dialog
                visible={showPerfilSorteado}
                header={"Atenção!"}
                onHide={() => setShowPerfilSorteado(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Cadastro encontrado! Por favor, preencha o formulário abaixo para realizar a sua matricula.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowPerfilSorteado(false)} />
            </Dialog>
            <Dialog
                visible={showTurmaCheia}
                header={"Atenção!"}
                onHide={() => setShowTurmaCheia(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Não foi possível realizar a matricula pois a turma está lotada!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowTurmaCheia(false)} />
            </Dialog>
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit} className="p-grid p-col-12 p-mx-0">
                    <div className="p-fluid">
                        {dataHoje >= inicioRematricula && dataHoje <= finalRematricula
                            ? <h2>FORMULÁRIO DE REMATRICULA</h2>
                            : <h2>FORMULÁRIO DE MATRICULA</h2>}
                        {dataHoje >= inicioRematricula && dataHoje <= finalRematricula
                            ?
                            <p>O prazo de realização das rematriculas é de {inicioRematriculaBr} a {finalRematriculaBr}</p>
                            :
                            <p>O prazo de realização das matriculas é de {inicioMatriculaBr} a {finalMatriculaBr}</p>}
                        <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-4">
                            <label
                                htmlFor="cpf"
                                className={classNames({ 'p-error': isFormFieldValid('cpf') })}
                            >
                                CPF
                            </label>
                            {
                                !isLoading ?
                                    <InputMask
                                        id="cpf"
                                        name="cpf"
                                        mask="999.999.999-99"
                                        unmask={true}
                                        value={formik.values.cpf}
                                        onChange={formik.handleChange}
                                        onComplete={handleCpfComplete}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cpf') })}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.cpf && formik.touched.cpf &&
                                <ErrorValidationMessage message={formik.errors.cpf} />
                            }
                        </div>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="alunoId"
                                    className={classNames({ 'p-error': isFormFieldValid('alunoId') })}
                                >
                                    Aluno
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="alunoId"
                                            name="alunoId"
                                            options={perfil}
                                            optionLabel="nome"
                                            optionValue="id"
                                            filter={true}
                                            value={formik.values.alunoId}
                                            className={classNames({ 'p-invalid': isFormFieldValid('alunoId') })}
                                            disabled
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.alunoId && formik.touched.alunoId &&
                                    <ErrorValidationMessage message={formik.errors.alunoId} />
                                }
                            </div>
                            {!instrumento
                                ?
                                <div className="p-field p-col-12 p-sm-4">
                                    <label
                                        htmlFor="instrumentoId"
                                        className={classNames({ 'p-error': isFormFieldValid('instrumentoId') })}
                                    >
                                        Instrumento
                                    </label>
                                    {
                                        !isLoading ?
                                            <Dropdown
                                                id="instrumentoId"
                                                name="instrumentoId"
                                                options={curso}
                                                optionLabel="nome"
                                                optionValue="id"
                                                emptyMessage="Nenhum instrumento encontrado"
                                                emptyFilterMessage={"Nenhum instrumento encontrado"}
                                                filter={true}
                                                value={formik.values.instrumentoId}
                                                onChange={(e) => formik.setFieldValue("instrumentoId", e.value)}
                                                className={classNames({ 'p-invalid': isFormFieldValid('instrumentoId') })}
                                                placeholder="Selecione um instrumento"
                                                showClear={true}
                                            />
                                            :
                                            <Skeleton height="35px" />
                                    }
                                    {formik.errors.instrumentoId && formik.touched.instrumentoId &&
                                        <ErrorValidationMessage message={formik.errors.instrumentoId} />
                                    }

                                </div>
                                :
                                <div className="p-field p-col-12 p-sm-4">
                                    <label
                                        htmlFor="renovar"
                                        className={classNames({ 'p-error': isFormFieldValid('renovar') })}
                                    >
                                        Renovar Matricula em {instrumentoNome}
                                    </label>
                                    {
                                        !isLoading ?
                                            <Checkbox
                                                id="renovar"
                                                name="renovar"
                                                checked={formik.values.renovar}
                                                onChange={() => formik.setFieldValue('renovar', !formik.values.renovar)}
                                                className={classNames({ 'p-invalid': isFormFieldValid('renovar') })}
                                                style={{ marginRight: '5px' }}
                                            />
                                            :
                                            <Skeleton height="35px" />
                                    }
                                    {formik.errors.renovar && formik.touched.renovar &&
                                        <ErrorValidationMessage message={formik.errors.renovar} />
                                    }
                                </div>
                            }

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="turmaId"
                                    className={classNames({ 'p-error': isFormFieldValid('turmaId') })}
                                >
                                    Turma
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="turmaId"
                                            name="turmaId"
                                            options={turma}
                                            emptyMessage={formik.values.cpf === "" ? "Preencha o CPF para encontrar a turma!" : apenasInstrumento ? "Turma Concluída!" : "Nenhuma turma disponível para esse ensino. Por gentileza, procurar a administração do conservatório."}
                                            emptyFilterMessage={"Nenhuma turma encontrado"}
                                            filter={true}
                                            value={formik.values.turmaId}
                                            onChange={(e) => formik.setFieldValue("turmaId", e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('turmaId') })}
                                            placeholder="Selecione uma turma"
                                            showClear={true}

                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.turmaId && formik.touched.turmaId &&
                                    <ErrorValidationMessage message={formik.errors.turmaId} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-14"  >
                                <label
                                    htmlFor="doc"
                                    className={classNames({ 'p-error': isFormFieldValid('doc') })}
                                >
                                    Anexar Imagem de Documento de Identidade com CPF (Apenas um arquivo)
                                </label>
                                {
                                    !isLoading ?

                                        <FileUpload
                                            id="doc"
                                            name="doc"
                                            ref={fileUploadRefDoc}
                                            disabled={disableUploadDoc}
                                            auto
                                            url="./matricula/uploadDoc"
                                            customUpload
                                            uploadHandler={handlerUpload}
                                            accept="image/*"
                                            maxFileSize="1000000"
                                            chooseOptions={selecionar}
                                            uploadOptions={enviar}
                                            cancelOptions={cancelar}
                                            onSelect={handleSelectDoc}
                                            onRemove={handleClearDoc}
                                            className={classNames({ 'p-invalid': isFormFieldValid('doc') })}
                                        />

                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.doc && formik.touched.doc &&
                                    <ErrorValidationMessage message={formik.errors.doc} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-14">
                                <label
                                    htmlFor="endereco"
                                    className={classNames({ 'p-error': isFormFieldValid('endereco') })}
                                >
                                    Anexar Imagem de Comprovante de Residência (Apenas um arquivo)
                                </label>
                                {
                                    !isLoading ?

                                        <FileUpload
                                            id="endereco"
                                            name="endereco"
                                            url='/upload'
                                            ref={fileUploadRefEnde}
                                            disabled={disableUploadEnde}
                                            auto
                                            customUpload
                                            uploadHandler={handlerUpload}
                                            accept="image/*"
                                            maxFileSize="1000000"
                                            chooseOptions={selecionar}
                                            uploadOptions={enviar}
                                            cancelOptions={cancelar}
                                            onSelect={handleSelectEnde}
                                            onRemove={handleClearEnde}
                                            className={classNames({ 'p-invalid': isFormFieldValid('endereco') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.endereco && formik.touched.endereco &&
                                    <ErrorValidationMessage message={formik.errors.endereco} />
                                }
                            </div>

                            <div className="p-d-flex p-jc-end p-mr-2">
                                {
                                    !isLoading ?
                                        <Button
                                            label="Enviar"
                                            type="submit"
                                            iconPos="right"
                                            loading={isSubmiting}
                                        />
                                        :
                                        <Skeleton width="82.16px" height="35px" />
                                }

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}