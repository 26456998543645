import React, { useRef, useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber} from 'primereact/inputnumber';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';

export const FormMatriculas = ({ isEditing, isLoading, defaultValues }) => {
    
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [perfil, setPerfil] = useState("");
    const [curso, setCurso] = useState("");
    const [turma, setTurma] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [showTurmaCheia, setShowTurmaCheia] = useState(false);
    const schema = Yup.object().shape({
        alunoId: Yup
            .number()
            .required("O aluno é obrigatório")
            .positive("O aluno deve ser selecionado")
            .integer("O aluno deve estar na lista"),     
        dataMatricula: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de matrícula é obrigatória"),
        status: Yup
            .string()
            .required("O Status é obrigatório")
            .max(200, "O status dete ter no máximo 200 caracteres"),  
        instrumentoId: Yup
            .number()
            .required("O instrumento é obrigatório")
            .positive("O instrumento deve ser selecionado")
            .integer("O instrumento deve estar na lista"),  
        turmaId: Yup
            .number()
            .required("A turma é obrigatório")
            .positive("A turma deve ser selecionado")
            .integer("A turma deve estar na lista"),      
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
           
            // console.log(formik.values);
            let result = await api.get(`perfil/${data.alunoId}`);
            let nome = result.data.nome;
            try {
                if (!isEditing) {

                    await api.post("matricula", data)

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/matriculas')

                    }, 2000)

                }
                else {
                    let data1 = {
                        matriculaId: data.id,
                        data: new Date(),
                        status: data.status,
                        statusAtual: true,
                    }
                    await api.put("matricula", data)
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${nome} atualizado(a) com sucesso` });
                    setTimeout(async () => {
                        if (data.status !== defaultValues.status)
                        {
                            await api.post("historicoStatus", data1)
                        }
                        history.push('/matriculas')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar matrícula!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar matrícula!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);


    const getPerfil = useCallback(async () => {
        try {
            const response = await api.get(`perfil/perfilAll`);
            setPerfil(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter perfil', detail: error.toString() });
        }
    }, []);
 
    const getCursos = useCallback(async () => {
        try {
            const response = await api.get(`curso/cursoAll`);
            setCurso(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    const getTurma = useCallback(async () => {
        try {
            const response = await api.get(`turma/turmaAll`);
            
            setTurma(response.data.map(turma => {
                var turm = {label: '', value: ''};
                turm.label = turma.perfilNome + " - " + turma.ano + " - " + turma.cursoSigla +  " - " + turma.diaSemana + "  " +turma.hora
                turm.value = turma.id 
                return turm
            }))
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter turma', detail: error.toString() });
        }
    }, []);

    useEffect(() => {

        async function matriculas() {
            await getPerfil();
            await getCursos();
            await getTurma();
        };
        matriculas();

    }, [getPerfil,getCursos,getTurma]);

    const handleTurmaChange = async (e) => {
        formik.setFieldValue("turmaId", e.value)
        let turmaTeoria = await api.get(`turma/${e.value}`)
        let qtdeTeoria = await api.get(`matricula/inscritos/${e.value}`)
        if (qtdeTeoria.data >= turmaTeoria.data.qtdeVagas)
        {
            setShowTurmaCheia(true);
            setDisabled(true);
        }
        else
        {
            setDisabled(false);
        }
    }

    const handleOkTurmaCheiaClick = () => {
        setShowTurmaCheia(false);
    }

    return (
                
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <Dialog
                visible={showTurmaCheia}
                header={"Atenção!"}
                onHide={() => setShowTurmaCheia(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Não foi possível realizar a matricula pois a turma está lotada!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={handleOkTurmaCheiaClick} />
            </Dialog>
            <div className="p-col-12">
                <h2 className="p-mt-0">Matrículas</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="alunoId"
                                className={classNames({ 'p-error': isFormFieldValid('alunoId') })}
                            >
                                Aluno
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="alunoId"
                                    name="alunoId"
                                    options={perfil}
                                    optionLabel="nome"
                                    optionValue="id"
                                    emptyMessage="Nenhum aluno encontrado"
                                    emptyFilterMessage={"Nenhum aluno encontrado"}
                                    filter={true}
                                    value={formik.values.alunoId}
                                    onChange={(e) => formik.setFieldValue("alunoId", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('alunoId') })}
                                    placeholder="Selecione um aluno"
                                    showClear={true} 
                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.alunoId && formik.touched.alunoId &&
                                <ErrorValidationMessage message={formik.errors.alunoId} />
                            }
                        </div>
                       


                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataMatricula"
                                    className={classNames({ 'p-error': isFormFieldValid('dataMatricula') })}
                                >
                                    Data Matrícula
                                </label>
                                <BrCalendar
                                    id="dataMatricula"
                                    name="dataMatricula"
                                    value={formik.values.dataMatricula}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataMatricula).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataMatricula') })}
                                    yearRange={`${new Date().getFullYear() - 3}:${new Date().getFullYear() + 2}`}
                                />
                                {formik.errors.dataMatricula && formik.touched.dataMatricula &&
                                    <ErrorValidationMessage message={formik.errors.dataMatricula} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="conclusao"
                                    className={classNames({ 'p-error': isFormFieldValid('conclusao') })}
                                >
                                    Conclusão
                                </label>
                                <BrCalendar
                                    id="conclusao"
                                    name="conclusao"
                                    value={formik.values.conclusao}
                                    onChange={formik.handleChange}
                                    placeholder={ formik.values.conclusao != null && isEditing ? new Date(formik.values.conclusao).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('conclusao') })}
                                    yearRange={`${new Date().getFullYear() - 3}:${new Date().getFullYear() + 2}`}
                                />
                                {formik.errors.conclusao && formik.touched.conclusao &&
                                    <ErrorValidationMessage message={formik.errors.conclusao} />
                                }
                        </div>



                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="status"
                                className={classNames({ 'p-error': isFormFieldValid('status') })}
                            >
                                Status
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        options={formik.values.statusLista}
                                        emptyMessage="Nenhum status encontrado"
                                        emptyFilterMessage={"Nenhum status encontrado"}
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("status", e.value)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('status') })}
                                        placeholder="Selecione o status"
                                        showClear={true} 
                                    />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.status && formik.touched.status &&
                                <ErrorValidationMessage message={formik.errors.status} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="instrumentoId"
                                className={classNames({ 'p-error': isFormFieldValid('instrumentoId') })}
                            >
                                Instrumento
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="instrumentoId"
                                    name="instrumentoId"
                                    options={curso}
                                    optionLabel="nome"
                                    optionValue="id"
                                    emptyMessage="Nenhum instrumento encontrado"
                                    emptyFilterMessage={"Nenhum instrumento encontrado"}
                                    filter={true}
                                    value={formik.values.instrumentoId}
                                    onChange={(e) => formik.setFieldValue("instrumentoId", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('instrumentoId') })}
                                    placeholder="Selecione um instrumento"
                                    showClear={true} 
                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.instrumentoId && formik.touched.instrumentoId &&
                                <ErrorValidationMessage message={formik.errors.instrumentoId} />
                            }
                        </div>      

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="turmaId"
                                className={classNames({ 'p-error': isFormFieldValid('turmaId') })}
                            >
                                Turma
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="turmaId"
                                    name="turmaId"
                                    options={turma}
                                    emptyMessage={"Nenhuma turma encontrada"}
                                    emptyFilterMessage={"Nenhuma turma encontrado"}
                                    filter={true}
                                    value={formik.values.turmaId}
                                    onChange={handleTurmaChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('turmaId') })}
                                    placeholder="Selecione uma turma"
                                    showClear={true} 

                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.turmaId && formik.touched.turmaId &&
                                <ErrorValidationMessage message={formik.errors.turmaId} />
                            }
                        </div>     

                        <div className="p-field p-col-12 p-sm-4">
                            <label htmlFor="notaFinal" className={classNames({ "p-error": isFormFieldValid("notaFinal") })}>
                                Nota Final
                            </label>
                            {
                            !isLoading ? 
                                

                                <InputNumber
                                    id="notaFinal"                                    
                                    name="notaFinal"    
                                    mode="decimal" minFractionDigits={2}                                                                    
                                    className={classNames({ 'p-invalid': isFormFieldValid('notaFinal') })}
                                    onChange={(e) => formik.setFieldValue("notaFinal", e.value)}
                                    locale="pt-BR"
                                    value={formik.values.notaFinal}
                                    disabled
                                    
                                /> 
                            : 
                            <Skeleton height="35px" />
                            }
                            {formik.errors.notaFinal && formik.touched.notaFinal && 
                            <ErrorValidationMessage message={formik.errors.notaFinal} />}
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    disabled={disabled}
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
    
    );
}